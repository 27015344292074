<template>
<div class="rc-container rc-font-small"> <!-- container 0 -->
    
    <div class="rc-row rc-font-bold rc-font-medium">
      <div
        v-if="vehicle.isStateDeleted()"
        class="rc-box-col-15 rc-text-centre">
        {{ catelog.get(catelogKeys.VEHICLE.INACTIVE_MESSAGE) }}
      </div>
    </div>

    <div class="rc-row"> <!-- row 2 -->
      
      <div class="rc-box-col-2">
        <VehicleImage
          size="width:100px;"
          :defaultValue="vehicle.data" />
      </div>
      
      <div class="rc-box-col-13">
        <div class="rc-container"> <!-- container 1 -->
          <div class="rc-row"> <!-- row 1.1 -->
            <!-- -->
            
            <div class="rc-box-col-2 rc-text-centre rc-text-value">
            {{ decode(vehicle.name()) }} <br>
            {{ decode(vehicle.summary()) }}
            </div>
            
            <div class="rc-box-col-1 rc-text-centre rc-text-value">
              <div class="rc-container">
                <div class="rc-row">
                  <div :class="'rc-box-col-15 rc-text-centre ' + toResultColour(vehicle.defectType())" style="padding: 2px;">
                    {{ vehicle.defectTypeDisplay() }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="vehicle.isTrailer()" class="rc-box-col-2 rc-text-centre rc-text-value">
              {{ vehicle.brakeTypeDisplay() }}
            </div>
            <div v-else class="rc-box-col-2 rc-text-centre rc-text-value">
              {{ vehicle.brakeTypeDisplay() }} <br>
              {{ vehicle.weight() }}<br>
              <div v-if="!vehicle.hasWeight()" class="rc-input-minor rc-text-centre" style="padding: 2px;">
                Missing<br> Weight
              </div>
            </div>
          
            <div class="rc-box-col-1 rc-text-centre  rc-text-value">
              {{ vehicle.odometer() }}
            </div>
            
            <div class="rc-box-col-2 rc-text-centre rc-text-value">
              {{ vehicle.plate() }}<br>
              {{ vehicle.jurisdiction() }}<br>
              {{ vehicle.plateExpiryDateDisplay() }} <br>
              <div 
                v-if="vehicle.isPlateExpiryDateValid() && vehicle.hasPlateExpired()"
                style="background-color:#000000;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                  Plate Expired, Out of Service
              </div>
              <div 
                v-else-if="vehicle.isPlateExpiryDateValid() && vehicle.plateExpiryDateDaysLeft() <= 7"
                style="background-color:#E90202;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                  Plate will expire in {{ vehicle.plateExpiryDateDaysLeft() }} days
              </div>
              <div 
                v-else-if="vehicle.isPlateExpiryDateValid() && vehicle.plateExpiryDateDaysLeft() <= 90"
                style="background-color:#F39F42;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                Plate will expire in {{ vehicle.plateExpiryDateDaysLeft() }} days
              </div>
            </div>
            
            <div class="rc-box-col-2 rc-text-centre rc-text-value">
              {{ vehicle.annualDateDisplay() }}<br>
              {{ vehicle.annualNumber() }} <br>
              <div 
                v-if="vehicle.isPmviValid() && vehicle.hasAnnualDateExpired()"
                style="background-color:#000000;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                  Safety Expired, Out of Service
              </div>
              <div 
                v-else-if="vehicle.isPmviValid() && vehicle.annualDateDaysLeft() <= 7"
                style="background-color:#E90202;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                  Safety will expire in {{ vehicle.annualDateDaysLeft() }} days
              </div>
              <div 
                v-else-if="vehicle.isPmviValid() && vehicle.annualDateDaysLeft() <= 90"
                style="background-color:#F39F42;color:#FFFFFF;padding: 2px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
                Safety will expire in {{ vehicle.annualDateDaysLeft() }} days
              </div>
            </div>
            
            <div class="rc-box-col-5 rc-text-centre rc-text-value">
              {{ vehicle.makeModelDisplay() }} <br>
              {{ categoryName(vehicle.category()) }} <br/>
              {{ vehicle.vin().toUpperCase() }} <br>
            </div>
            
            <!-- -->
          </div> <!-- end row 1.1 -->
          
          <LabelValueRow v-if="isAdmin">
            <template #label>ID</template>
            <template #value>{{ vehicle.id() }} </template>
          </LabelValueRow>
          
          <Row v-if="isAdmin" :showBorder="true">
            <div class="rc-box-col-2 rc-text-label">Company</div>
            <div class="rc-box-col-3 rc-text-value">
              <Value>{{ vehicle.company().find().name() }}</Value>
            </div>
            <div class="rc-box-col-1" v-if="!vehicle.isNew() && !vehicle.company().isNew()">
              <Button v-on:click="companyDetails(vehicle.company().id())">Details</Button>
            </div>
            <div class="rc-box-col-9" />
          </Row>
          
          
          <div class="row">
          </div>
        </div> <!-- end container 1 -->
      </div> <!-- end col 13 -->
      
    </div> <!-- end row 2-->
    
</div> <!-- end container 0 -->
</template>

<script>

import { mapGetters } from "vuex";

import ContentUtils from "@/utils/ContentUtils.js";
import ConstUtils from "@/utils/ConstUtils.js";
import DateUtils from "@/utils/DateUtils.js";
import VehicleImage from "@/views/portal/vehicles/VehicleImage.vue";
import Catelog from '@/domain/session/CanadianEnglish.js';
import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
//import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
import Button from "@/portals/shared/library/button/Button.vue";

export default {
  name: "vehicle-row",
  components: {
    VehicleImage,
    LabelValueRow,
    Button,
//    SpacerRow,
  },
  props: {
    vehicleData: Object,
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      "auth_user",
      "auth_client",
      "auth_connected",
      "vehicles_found",
      "inspections_found",
      "companys_found",
      "schedules_found",
      "categorys_found",
      "domain",
    ]),
    vehicle: function() {
      return new Vehicle(this.domain, this.vehicleData);
    }
  },
  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    defectType: function (vehicle) {
      if (!vehicle) {
        return "";
      }
      
      return vehicle.defectType;
    },

    categoryName: function (category) {
      var list = ContentUtils.CATEGORIES();
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        if (item['@rid'] == category.id()) {
          return item['name'];
        }
      }
      return category.find().name();
    },
    
    companyName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      var id = vehicle[ConstUtils.FIELDS.COMPANY][ConstUtils.FIELDS.ID];
      var name = id;
      var company = this.companys_found.map[id];
      if (company) {
        name = company.name;
      }
      return name;
    },

    scheduleName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      var id = vehicle[ConstUtils.FIELDS.SCHEDULE][ConstUtils.FIELDS.ID];
      var name = id;
      var schedule = this.schedules_found.map[id];
      if (schedule) {
        name = schedule.name;
      }
      return name;
    },

    typeName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      var value = "";
      
      if (vehicle['make']) {
        value = vehicle['make'];
      }
      
      if (vehicle['model']) {
        value = value + " " + vehicle['model'];
      }
      return value;
    },

    isTrailer: function(vehicle) {
      var category = vehicle.category();
      return "#23:5" === category.id();
    },
    
    toDate: function (dateValue) {
      return ContentUtils.decodeDate(dateValue);
    },

    toDays: function (dateValue) {
      var today = new Date();
      var nowTime = today.getTime();
      var cvorDate = new Date(dateValue);
      var cvorTime = cvorDate.getTime();
      var diffTime = nowTime - cvorTime;
      var days = Math.round(diffTime / (60000 * 60 * 24));
      return days;
    },

    toOdometer: function (vehicle) {
      if (vehicle.odometer) {
        return vehicle.odometer;
      }
      return "0";
    },
    
    toBrakingType: function (type) {
      if (type === "abs") {
        return "Air Brakes";
      }
      if (type === "ebs") {
        return "Electric Brakes";
      }
      if (type === "hbs") {
        return "Hydraulic Brakes";
      }
      return "";
    },
    
    toResultColour: function (result) {
      if (result === "None" || result === "") {
        return "rc-input-good";
      }
      if (result === "Minor") {
        return "rc-input-minor";
      }
      if (result === "Major") {
        return "rc-input-major";
      }
      return "";
    },
    toResult: function (result) {
      if (result === "None" || result === "") {
        return "Good";
      }
      if (result === "Minor") {
        return "Minor Defects";
      }
      if (result === "Major") {
        return "Major Defects";
      }
      return "";
    },

    toColour: function (dateValue) {
      var days = this.toDays(dateValue);
      var display = {
        hasMessage: false,
      };
      if (!dateValue) {
        return display;
      }
      if (days >= DateUtils.yearInDays() - 90) {
        display.hasMessage = true;
        display["message"] =
          "Annual Safety Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#F9E425;";
      }
      if (days >= DateUtils.yearInDays() - 60) {
        display.hasMessage = true;
        display["message"] =
          "Annual Safety Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#F39F42";
      }
      if (days >= DateUtils.yearInDays() - 30) {
        display.hasMessage = true;
        display["message"] =
          "Annual Safety Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#E90202;color:#FFFFFF;";
      }
      if (days > DateUtils.yearInDays()) {
        display.hasMessage = true;
        display["message"] = "Annual Safety Expired!";
        display["colour"] = "background-color:#000000;color:#FFFFFF;";
      }
      return display;
    },
    
    companyDetails: function (companyId) {
      var params = {
          id: companyId, 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
            params: params,
          });
    },
  },
  
};
</script>